<template>
  <div class="NavWrapper">
    <div class="ContentWrapper">
      <div class="LeftNav">
<!--        <img id="TGDLogo" src="@/assets/tgd_white_small.png" alt="#">-->
        <img id="TGDLogo" src="./../assets/tgd_white.png" alt="#">
        <div class="TextLogo">
          <div id="TG">
            Tyler Grinstead
          </div>
          <div id="Design">
            Design
          </div>
        </div>
      </div>
      <div class="RightNav">
        <div class="RightLinks">
<!--          <ul class="Links">-->
<!--            <li class="NavLink">My Work</li>-->
<!--            <li class="NavLink">About Me</li>-->
<!--          </ul>-->
        </div>
        <span class="EmailLink"><a href="mailto: tyler@tgdesign.io">Email Me</a></span><span class="NavText">or</span>
        <a href="https://app.hellobonsai.com/s/tgdesign/e2cf8b47-296f-4842-a493-3563d8adee23" target="_blank"><button class="TalkButton">
          Let's Talk
        </button></a>
      </div>
    </div>
  </div>
</template>

<script setup>


</script>

<style scoped>

/*font-family: 'Kanit', sans-serif;*/
/*font-family: 'Raleway', sans-serif;*/

a {
  text-decoration: none;
  color: #ff7849;
}

.NavWrapper {
  height: 80px;
  //background-color: #FBFAF9;
  background-color: #161615;
}
.ContentWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-inline: 1rem;
}
.LeftNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}
#TGDLogo {
  max-height: 65px;
}
.TextLogo {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin-left: .5rem;
}
#TG {
  font-family: 'Raleway', sans-serif;
  //color: #292A2A;
  color: white;
  font-size: 1.6rem;
}
#Design {
  font-family: 'Kanit', sans-serif;
  color: #ff7849;
  font-size: 1.75rem;
}
.RightNav {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  color: #292A2A;
  font-family: 'Kanit', sans-serif;
  margin-right: .5rem;
}
.RightLinks {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}

.EmailLink {
  font-size: 1.1rem;
  font-weight: 400;
  margin-right: .5rem;
  cursor: pointer;
  color: #ff7849;
}

.NavText {
  font-size: 1.1rem;
  font-weight: 400;
  margin-right: .5rem;
  color: white;
}

/*.Links {*/
/*  list-style: none;*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  height: 100%;*/
/*  align-items: center;*/
/*}*/
/*.NavLink {*/
/*  display: block;*/
/*  margin-right: 2.5rem;*/
/*  cursor: pointer;*/
/*  position: relative;*/
/*}*/
/*.NavLink:after {*/
/*  content: '';*/
/*  position: absolute;*/
/*  left: 0;*/
/*  bottom: 0;*/
/*  width: 100%;*/
/*  height: 2px;*/
/*  transform: scaleX(0);*/
/*  background-color: #ff7849;*/
/*  transform-origin: bottom right;*/
/*  transition: transform 250ms ease-in-out;*/
/*}*/
/*.NavLink:hover:after {*/
/*  transform: scaleX(1);*/
/*  transform-origin: bottom left;*/
/*}*/
.TalkButton {
  color: #ff7849;
  border: 2px solid #ff7849;
  background-color: #161615;
  border-radius: 6px;
  height: 48px;
  width: 100px;
  font-family: 'Kanit', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  padding: 0;
  //border: none;
  cursor: pointer;
  transition: all ease-in-out 300ms;
}
.TalkButton:hover {
  //background-color: transparent;
  color: #fff;
  background-color: #ff7849;
  scale: 1.05;
  border-radius: .25rem;
  border: 2px solid #ff7849;
  -webkit-box-shadow: 0 12px 16px -6px rgba(255,120,73,0.69);
  box-shadow: 0 12px 16px -6px rgba(255,120,73,0.69);
}

@Media screen and (max-width: 900px) {
  #TGDLogo {
    display: none;
  }
}

@Media screen and (max-width: 630px) {
  #TGDLogo {
    max-height: 45px;
  }
  #TG {
    font-size: 1rem;
  }
  #Design {
    font-size: 1.1rem;
  }
  #TGDLogo {
    display: none;
  }
}

@Media screen and (max-width: 500px) {
  .TalkButton {
    display: none;
  }
  .NavText {
    display: none;
  }
}


@Media screen and (max-width: 389px) {
  #TG {
    font-size: .8rem;
  }
  #Design {
    font-size: .9rem;
  }
  #TGDLogo {
    display: none;
  }
}

</style>
