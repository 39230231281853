<template>
  <Home />
</template>
<script>
import Home from './components/Home.vue'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200;300;400;500;600&family=Raleway:wght@300;400;500;600&display=swap');
body {
  margin: 0;
}
</style>