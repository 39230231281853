<template>
  <div class="ServicesWrapper">
    <h1 id="Title">Process // <span id="TitleSpan">Services</span></h1>
    <div class="Update">
      <h3><span id="UpdateSpan">Note:</span> This site is currently in development and things may not look right at the moment. Bear with me as I
        work on getting everything up and running correctly. Thanks.</h3>
    </div>
    <div class="ContentWrapper">
      <div class="ContentLeft">
        <div class="LeftWrapper">
          <h1 class="SubTitle">This is my typical process:</h1>
          <ul class="ProcessList">
            <li>Book a discovery call <span class="ProcessSpan">We'll find out if we make for a good fit!</span></li>
            <li>Project start meeting <span class="ProcessSpan">So we've agreed to work together. This meeting establishes timeframes, goals, and other project specifics.</span>
            </li>
            <li>Wireframe <span class="ProcessSpan">Focusing on user experience to rough out the elements of your website.</span>
            </li>
            <li>Prototype <span class="ProcessSpan">Updating the chosen wireframe with all the branding items and content for a complete prototype.</span>
            </li>
            <li>Website development <span class="ProcessSpan">The rubber hits the road and the coding begins!</span>
            </li>
            <li>Project completion <span
                class="ProcessSpan">The website is done and hosted, live for the world to see!</span></li>
            <li>Ongoing partnership <span class="ProcessSpan">Delivering the site is done, but the work doesn't stop there. Frequent upkeep on SEO, updates, maintenance, etc. help keep the site exactly as YOU want it to be.</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="ContentRight">
        <div class="RightWrapper">
          <h1 class="SubTitle">I provide the following services:</h1>
          <ul class="ServicesList">
            <li><img class="Icon" src="@/assets/webdev.png" alt="#"> Web Development</li>
            <li><img class="Icon" src="@/assets/webdesign.png" alt="#"> Web Design</li>
            <li><img class="Icon" src="@/assets/onlinemarketing.png" alt="#"> Online Marketing</li>
            <li><img class="Icon" src="@/assets/seo.png" alt="#"> Search Engine Optimization</li>
            <li><img class="Icon" src="@/assets/logodesign.png" alt="#"> Logo Design</li>
            <li><img class="Icon" src="@/assets/more.png" alt="#"> More...</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>



</script>

<style scoped>

.ServicesWrapper {
  min-height: 500px;
  //background-color: #161615;
  background-color: #f2f2f2;
  border-top: #ff7849 3px solid;
  display: flex;
  flex-direction: column;
  padding-inline: 1rem;
}
.Update {
  text-align: center;
  font-family: 'Raleway', sans-serif;
  color: #292A2A;
  font-size: .8rem;
  font-weight: 300;
  margin-inline: auto;
  padding-inline: 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border: 1px solid #ff7849;
  //color: #fff;
}
#UpdateSpan {
  color: #ff7849;
  font-family: 'Kanit', sans-serif;
  font-size: 1.05rem;
  font-weight: 400;
}
.ContentWrapper {
  display: flex;
  flex-direction: row;
  margin-inline: auto;
  width: 100%;
  max-width: 1920px;
  justify-content: space-between;
  padding-bottom: 3em;
}
.LeftWrapper {
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  padding-inline: 2rem;
}
.RightWrapper {
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  padding-inline: 2rem;
}
.ContentLeft {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-right: auto;
  width: 50%;
}
.ContentRight {
  display: flex;
  flex-direction: column;
  margin-right: 0;
  margin-left: auto;
  width: 50%;
}
#Title {
  margin: 0;
  padding-top: .5rem;
  font-size: 18px;
  color: #ff7849;
  font-weight: 400;
}
#TitleSpan {
  font-family: 'Kanit', sans-serif;
  color: #292A2A;
  //color: #fff;
  font-size: 20px;
  font-weight: 300;
}
.SubTitle {
  display: block;
  font-size: 2rem;
  color: #292A2A;
  //color: #fff;
  font-family: 'Kanit', sans-serif;
  font-weight: 200;
  border-bottom: #ff7849 1px solid;
  width: fit-content;
  margin-bottom: .125rem;
}
ul {
  list-style: none;
}
.ServicesList {
  color: #292A2A;
  //color: #fff;
  font-family: 'Raleway', sans-serif;
  line-height: 2rem;
  font-size: 1rem;
}
.Icon {
  margin-right: .5rem;
}
.ProcessList {
  color: #ff7849;
  font-family: 'Kanit', sans-serif;
  line-height: 2rem;
  font-size: 1.2rem;
}
.ProcessSpan {
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  color: #292a2a;
  //color: #fff;
  margin-left: .5rem;
}

@media only screen and (max-width: 950px) {
  .ContentWrapper {
    flex-direction: column;
    margin-inline: auto;
    width: 100%;
  }
  .ContentLeft {
    width: 100%;
  }
  .ContentRight {
    width: 100%;
  }
  .LeftWrapper {
    margin-inline: 0;
  }
  .RightWrapper {
    margin-inline: 0;
  }
}

</style>
