<template>
  <div class="HeroWrapper">
    <div class="ContentWrapper">
      <h1>Freelance Web Development and Design</h1>
      <p>I build websites with your specific needs and goals in mind. I will work with you to build a website that feels
        like you or your business and feels familiar to your customers and clients. No templates. No corners cut. Your
        website will have a custom and thoughtful design with a good user experience. My goal is to create something
        you’re proud to show your friends, family, and colleagues, but most importantly generates more revenue, clients,
        and reach.</p>
      <p>Schedule a discovery call or <span class="EmailLink"><a href="mailto: tyler@tgdesign.io">Email Me</a></span> and let’s see if we make a great team!</p>
      <div class="ButtonWrapper">
        <a href="https://app.hellobonsai.com/s/tgdesign/e2cf8b47-296f-4842-a493-3563d8adee23" target="_blank">
          <Button class="TalkButton">Let's Talk</Button>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>

a {
  text-decoration: none;
  color: #ff7849;
}

.HeroWrapper {
  display: flex;
  //background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.02), rgba(002, 002, 002, 0.73)), url("./../assets/herobg1.webp");
  background-image: url("./../assets/herobg1.webp");
  background-position: 50% 20%;
  //background-repeat: no-repeat;
  //min-height: 1000px;
  min-height: 93.5vh;
  padding-inline: 1rem;
  background-color: #0F1130;
}

.ContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 750px;
  text-align: center;
  padding-top: 4rem;
  /*color: #f2f2f2;*/
}
.EmailLink {
  font-size: 1.1rem;
  font-weight: 400;
  cursor: pointer;
  color: #ff7849;
}

h1 {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  //color: #292A2A;
  color: white;
  font-size: 2.375rem;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

p {
  //color: #292a2a;
  color: white;
  font-size: 1.125rem;
  font-family: 'Kanit', sans-serif;
  font-weight: 300;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.TalkButton {
  font-size: 1.25rem;
  //border: 2px solid #292a2a;
  border: 2px solid #fff;
  background-color: transparent;
  //color: #292a2a;
  color: white;
  padding-inline: 2rem;
  padding-top: .75rem;
  padding-bottom: .75rem;
  transition: ease-in-out;
  transition-duration: 300ms;
  cursor: pointer;
  margin-top: 1.5rem;
  border-radius: .25rem;
}

.TalkButton:hover {
  //background-color: #ff7849;
  background-color: #ff7849;
  color: #fff;
  scale: 1.05;
  border-radius: .25rem;
  //border: 2px solid #ff7849;
  border: 2px solid #ff7849;
  -webkit-box-shadow: 0 12px 16px -6px rgba(255, 120, 73, 0.69);
  box-shadow: 0 12px 16px -6px rgba(255, 120, 73, 0.69);
  //-webkit-box-shadow: 0 12px 16px -6px rgba(240, 91, 241, 0.69);
  //box-shadow: 0 12px 16px -6px rgba(240, 91, 241, 0.69);
}

@Media screen and (max-width: 630px) {
  .HeroWrapper {
    height: 700px;
    max-height: 700px;
  }
}

</style>
