<template>
  <div class="FeaturedWrapper">
    <div class="ContentWrapper">
      <h1 id="Title">Featured Project // <span id="TitleSpan"><a href="https://www.smallsandpetite.com" target="_blank">www.smallsandpetite.com</a></span></h1>
      <div class="LogoDiv">
      <a href="https://www.smallsandpetite.com" target="_blank"><img id="SPLogo" src="@/assets/smallsandpetite.png" alt="#"></a>
      </div>
        <div class="ProjectWrapper">
        <div class="ProjectGrid">
          <div id="ProjectMain">
            <img id="GridMainImg" src="@/assets/grid1.png" alt="#">
          </div>
          <div id="ProjectTop">
            <img id="GridSecondImg" src="@/assets/grid2.png" alt="#">
          </div>
          <div id="ProjectBottom">
            <img id="GridThirdImg" src="@/assets/grid3.png" alt="#">
          </div>
        </div>
      </div>
      <div class="ProjectOverviewDiv">
        <h4 id="ParaHead">Smalls & Petite makes beautifully illustrated yard art to celebrate many different occasions!</h4>
        <p>When I first started working with them, they were just getting started launching their brand and were mainly
          taking orders in person or through social platforms such as Facebook and Instagram.
        Together, we established that the target demographic for this site was to be parents / grand-parents who
          were in the upper-middle class. In order to appeal to a more classy look, the site remained relatively simple
          and mono-colored, letting the illustrations themselves provide the color and character.</p>
        <p>It was an absolute pleasure to work with Smalls & Petite on their website and I hope you check them out!</p>
        <br />
        <div class="ButtonWrapper">
          <a href="https://www.smallsandpetite.com" target="_blank"><Button class="FeaturedButton">Visit Site</Button></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>

a {
  text-decoration: none;
  color: inherit;
}
.FeaturedWrapper {
  background-color: #161615;
  padding-inline: 1rem;
  border-top: 3px #ff7849 solid;
}
#Title {
  margin: 0;
  padding-top: .5rem;
  font-size: 18px;
  color: #ff7849;
  font-weight: 500;
}
#TitleSpan {
  font-family: 'Kanit', sans-serif;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
}
.ProjectWrapper {
  margin-inline: auto;
  width: 100%;
  max-width: 1920px;
  margin-top: 4rem;
}
.LogoDiv {
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
  justify-content: center;
}
.ProjectGrid {
  display: grid;
  grid-template-columns: 1000px 500px;
  grid-template-rows: repeat(2, auto);
  grid-gap: 0;
  justify-items: stretch;
  align-items: stretch;
  margin-inline: auto;
  width: 100%;
  max-width: 1500px;
}
#ProjectMain {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 3;
  justify-self: stretch;
  align-self: stretch;
}
#ProjectTop {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  justify-self: stretch;
  align-self: stretch;
}
#ProjectBottom {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
  justify-self: stretch;
  align-self: stretch;
}
.ProjectOverviewDiv {
  width: 100%;
  max-width: 1500px;
  margin-inline: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: #f2f2f2;
}
#ParaHead {
  display: flex;
  width: 100%;
  margin-inline: auto;
  justify-content: center;
  margin-bottom: 2rem;
  font-size: 1.6rem;
}
.ButtonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.FeaturedButton {
  font-size: 1.25rem;
  border: 2px solid #f2f2f2;
  //border: none;
  background-color: #161615;
  //background-color: #f2f2f2;
  padding-inline: 2rem;
  padding-top: .75rem;
  padding-bottom: .75rem;
  transition: ease-in-out;
  transition-duration: 300ms;
  cursor: pointer;
  margin-top: 1.5rem;
  margin-inline: auto;
  color: #f2f2f2;
  border-radius: .25rem;
}
.FeaturedButton:hover {
  background-color: #ff7849;
  color: #f2f2f2;
  scale: 1.05;
  border-radius: .25rem;
  border: 2px solid #ff7849;
  -webkit-box-shadow: 0 12px 16px -6px rgba(255,120,73,0.69);
  box-shadow: 0 12px 16px -6px rgba(255,120,73,0.69);
}

@media only screen and (max-width: 1520px) {
  .ProjectGrid {
    grid-template-columns: 100%;
  }
  #ProjectMain {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    justify-self: center;
    align-self: center;
  }
  #ProjectTop {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
    justify-self: center;
    align-self: center;
  }
  #ProjectBottom {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 3;
    grid-row-end: 3;
    justify-self: center;
    align-self: center;
  }
}

@media only screen and (max-width: 1075px) {
  #GridMainImg {
    width: 500px;
  }
}

@media only screen and (max-width: 1075px) {
  #GridMainImg {
    width: 500px;
  }
}

@Media only screen and (max-width: 500px) {
  #GridMainImg {
    width: 390px;
  }
  #GridSecondImg {
    width: 390px;
  }
  #GridThirdImg {
    width: 390px;
  }
}
</style>
