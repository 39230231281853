<template>
  <div class="FooterWrapper">
    <div class="Copyright">
      <p>Copyright© {{ currentYear }}, TG Design and Development, LLC all rights reserved.</p>
    </div>
    <div class="Bottom">
      <div class="Left">
        <img id="TGDLogo" src="./../assets/tgd_white.png" alt="#">
        <h3 id="TG">Tyler Grinstead <span id="DesignSpan">Design</span></h3>
      </div>
      <div class="Right">
        <div class="Social">
          <a href="https://www.instagram.com/txgrinstead/" id="InstagramIcon" target="_blank"><img src="./../assets/instaL.png" alt="#"></a>
          <a href="https://www.facebook.com/tylergrinsteaddesign" id="FacebookIcon" target="_blank"><img src="./../assets/faceL.png" alt="#"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const currentYear = (String(new Date().getFullYear()));
</script>

<style scoped>

.FooterWrapper {
  background-color: #ff7849;
  color: #3E3E3E;
  position: relative;
  align-items: center;
  padding-top: .25rem;
  margin-top: auto;
  margin-bottom: auto;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: 1rem;
}
.Bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.Copyright {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}

.Left {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}

.Right {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}

.Social {
  display: flex;
  flex-direction: row;
  width: 75px;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
}

#TGDLogo {
  margin-right: .5rem;
  height: 60px;
  margin-bottom: 3px;
}

#TG {
  font-family: 'Raleway', sans-serif;
  color: #3E3E3E;
  font-size: 1.3rem;
}

#DesignSpan {
  font-size: 1.3rem;
  color: #fff;
}

@media only screen and (max-width: 1075px) {
  #TG {
    display: none;
  }

  #DesignSpan {
    display: none;
  }
}

@media only screen and (max-width: 680px) {
  .Copyright {
    font-size: .8rem;
    max-width: 250px;
    margin-inline: auto;
  }

  #TGDLogo {
    max-height: 35px;
  }
}

@media only screen and (max-width: 580px) {
  .Social {
    width: 70px;
  }

  .FooterWrapper {
    padding-inline: .5rem;
  }
}

@media only screen and (max-width: 532px) {
  .FooterWrapper {
    flex-direction: column;

  }
  #TGDLogo {
    margin-top: .25rem;
  }
  .Social {
    margin-top: .25rem;
  }
}

</style>
